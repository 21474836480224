export enum SegmentEventType {
  ADD_TO_GOOGLE_CALENDAR_EVENT = 'AddtoGoogleCalendarEvent',
  ADD_TO_OFFICE365_CALENDAR_EVENT = 'AddtoOffice365CalendarEvent',
  ADD_TO_OUTLOOK_CALENDAR_EVENT = 'AddtoOutlookCalendarEvent',
  ADD_TO_YAHOO_CALENDAR_EVENT = 'AddtoYahooCalendarEvent',
  CALENDLY = 'calendly',
  CANCEL_SUBSCRIPTION_PAYMENT_BUTTON = 'cancelSubscriptionPaymentButton',
  CASE_STUDY_APPLY = 'caseStudyApply',
  Clicked_Book_Session = 'clickedBookSession',
  Confirmed_Booking = 'confirmedBooking',
  DOWNLOAD_CALENDAR_EVENT = 'DownloadCalendarEvent',
  FREE_ENROLLMENT = 'freeEnrollment',
  FREQUENCY_TOGGLE = 'frequencyToggle',
  JOB_APPLY = 'jobApply',
  COMMUNITY_BUTTON = 'communityBtnClicked',

  JoinSessionClicked = 'joinSessionClicked',

  LOGIN_BUTTON = 'loginButton',
  LOGIN_WITH_GOOGLE_BUTTON = 'loginwithGoogleButton',
  LOGOUT_BUTTON = 'logoutButton',
  MENTOR_BOOKED = 'mentorBooked',
  PAID_ENROLLMENT = 'paidEnrollment',
  PAYMENT_INTENT = 'paymentIntent',
  PRODUCT_PURCHASED = 'Product Purchased',
  PROFILE_PIC_TO_PROFILE = 'profilePicToProfileRedirect',

  PUBLIC_JOB_REDIRECT = 'publicJobRedirect',

  SIDEBAR_PROFILE_NUDGE = 'sidebarProfileNudge',
  SIGNUP_LINK_CLICK = 'signupLinkClick',
  SIGNUP_WITH_GOOGLE_BUTTON = 'signupWithGoogleButton',
  START_FREE_TRIAL_BUTTON = 'startFreeTrialButton',
  Selected_Booking_Date = 'selectedBookingDate',
  Selected_Booking_Time = 'selectedBookingTime',
  TRY_MEMBERSHIP_BUTTON = 'tryMembershipButton',
  VERIFY_EMAIL_BUTTON = 'verifyEmailButton',
  Viewed_Bookings = 'viewedBookings',

  AI_DESCRIPTION_GENERATOR = 'aiJDRequested',
  AI_INTERVIEW_QUESTIONS_GENERATOR = 'aiInterviewQuestionsRequested',

  APPRENTICE_ONBOARDING_STEP_2 = 'userDepartment',
  APPRENTICE_ONBOARDING_COMPLETE = 'apprenticeOnboardingCompleted',

  CORPORATE_SIGNUP_CLICK = 'corporateSignupClick',
  // Corporate
  Corp_VisitedDashboard = 'visitedDashboard',
  Corp_ClickedCompleteProfileButton = 'clickedCompleteProfileButton',
  Corp_ClickedEnableNotificationButton = 'clickedEnableNotificationButton',
  Corp_ClickedJobSourceCandidate = 'clickedJobSourceCandidate',
  Corp_ClickedViewCandidates = 'clickedViewCandidates',
  Corp_ClickedCandidateInvite = 'clickedCandidateInvite',
  Corp_ClickedChatCompletion = 'Chatcompletion',
  Corp_ClickedSourceCandidate = 'clickedSourceCandidate',
  Corp_IrisJobCreationFailed = 'irisJobCreationFailed',
  Corp_OutreachModalUpgradeCta = 'clickedOutreachUpgradeButton',
  Corp_OutreachModalUpgradeNoteText = 'clickedOutreachUpgradeNoteText',
  Corp_OutreachModalCancelCta = 'clickedOutreachCancelButton',
  Corp_PlansModalCustomPlanContactUsCta = 'clickedPlansModalCustomPlanCta',
  Corp_PlansModalCancelCta = 'clickedPlansModalCancelCta',
  Corp_PlansModalPlanCardCta = 'clickedPlansModalPlanCardCta',
  Corp_PlanCheckoutDetailsModalPurchaseCta = 'clickedPlanCheckoutDetailsModalPurchaseCta',
  Corp_PlanCheckoutDetailsModalCancelCta = 'clickedPlanCheckoutDetailsModalCancelCta',
  Corp_StripeCheckoutFormCancelCta = 'clickedStripeCheckoutFormCancelCta',
  Corp_StripeCheckoutFormBuyCta = 'clickedStripeCheckoutFormBuyCta',
  Corp_PaymentSuccess = 'corpSubscriptionSuccess',
  Corp_ManagePlansBillingPageBuySubscriptionCta = 'clickedManagePlanBuySubscriptionCta',
  Corp_HistoryPageInSettingsPageBuyPlanCta = 'clickedBuyPlanCtaHistoryPageInManagePLan',
  Corp_ManagePlansBillingPageUpgradeCta = 'clickedManagePlanUpgradeCta',
  Corp_ManagePlansBillingPageCancelCta = 'clickedManagePlanCancelCta',
  Corp_ManagePlansBillingPageRestoreCta = 'clickedManagePlanRestoreCta',
  Corp_ManagePlansBillingPagePaymentDueCta = 'clickedManagePlanPaymentDueCta',
  Corp_ViewedCandidateFirstTime = 'viewedCandidateFirstTime',
  Corp_Thumbup = 'thumbUp',
  Corp_Thumbdown = 'thumbDown',
  Corp_ThumbupRemoved = 'thumbUpRemoved',
  Corp_ThumbdownRemoved = 'thumbDownRemoved',
  Corp_LeadGenJobsPageCta = 'clickedLeadGenJobsPageCta',
  Corp_LeadGenHireMeCta = 'clickedLeadGenHireMeCta',

  Corp_SignupNextClicked = 'corporateSignupNextClicked',
  Corp_SignupBackClicked = 'corporateSignupBackClicked',

  Corp_ClosedJob = 'corporateClosedJob',

  Corp_LinkedinOutreachUserSignin = 'corporateLinkedinSigninForOutreach',

  // V2 jobs
  Corp_CreateJobCta = 'corporateCreateJobCta',
  Corp_JobsTableStageClicked = 'corporateJobsTableStageClicked',
  Corp_JobsTableRowClicked = 'corporateJobsTableRowClicked',
  Corp_JobsTableRowDeleteClicked = 'corporateJobsTableRowDeleteClicked',
  Corp_JobDetailsBoostJobClicked = 'corporateJobDetailsBoostJobClicked',
  Corp_JobsDetailsTabClicked = 'corporateJobsDetailsTabClicked',
  Corp_JobDetailsStatusChangeClicked = 'corporateJobDetailsStatusChangeClicked',
  Corp_JobDetailsEditJobCtaClicked = 'corporateJobDetailsEditJobCtaClicked',
  Corp_JobDetailsViewJobCtaClicked = 'corporateJobDetailsViewJobCtaClicked',
  Corp_JobDetailsPipelineStageCandidateClicked = 'corporateJobDetailsPipelineStageCandidateClicked',
  Corp_JobDetailsPipelineStageCandidateDragged = 'corporateJobDetailsPipelineStageCandidateDragged',
  Corp_JobDetailsPipelineStageCandidateDropped = 'corporateJobDetailsPipelineStageCandidateDropped',
  Corp_JobDetailsPipelineCandidateMoveToItemClicked = 'corporateJobDetailsPipelineCandidateMoveToItemClicked',
  Corp_JobDetailsPipelineCandidateRejected = 'corporateJobDetailsPipelineCandidateRejected',
  Corp_JobDetailsPipelineCandidateUnlocked = 'corporateJobDetailsPipelineCandidateUnlocked',
  Corp_CandidateNotesAddClicked = 'corporateCandidateNotesAddClicked',
  Corp_CandidateNotesDeleteClicked = 'corporateCandidateNotesDeleteClicked',
  Corp_CampaignsAddToCampaignClicked = 'corporateCampaignsAddToCampaignClicked',
  Corp_TalentPoolAddToTalentPoolClicked = 'corporateTalentPoolAddToTalentPoolClicked',
  Corp_VideoAssessmentCandidateInvited = 'corporateVideoAssessmentCandidateInvited',
  Corp_JobDetailsRecommendCandidatesToJobClicked = 'corporateJobDetailsRecommendCandidatesToJobClicked',
  Corp_JobDetailsApplicationsModeClicked = 'corporateJobDetailsApplicationsModeClicked',
  Corp_JobDetailsPipelineStageClicked = 'corporateJobDetailsPipelineStageClicked',
  Corp_JobFormSubmitJobClicked = 'corporateJobDetailsSubmitJobClicked',
  Corp_SourceWorkspaceSwitched = 'corporateSourceWorkspaceSwitched',
  Corp_SourceAddNewSourceClicked = 'corporateSourceAddNewSourceClicked',
  Corp_SourceConversationClicked = 'corporateSourceConversationClicked',
  Corp_SourceRenameClicked = 'corporateSourceRenameClicked',
  Corp_SourceDeleteClicked = 'corporateSourceDeleteClicked',
  Corp_SourcePromptListItemClicked = 'corporateSourcePromptListItemClicked',
  Corp_SourceSendClicked = 'corporateSourceSendClicked',
  Corp_SourceEditSearchClicked = 'corporateSourceEditSearchClicked',
  Corp_SourceFilterDrawerOpened = 'corporateSourceFilterDrawerOpened',
  Corp_SourceCandidateClicked = 'corporateSourceCandidateClicked',
  Corp_SourceCandidateSelected = 'corporateSourceCandidateSelected',
  Corp_SourceCandidateUnselected = 'corporateSourceCandidateUnselected',
  Corp_SourceSearchTabClicked = 'corporateSourceSearchTabClicked',
  Corp_SourceAIScreeningTabClicked = 'corporateSourceAIScreeningTabClicked',
  Corp_SourceFavoritesTabClicked = 'corporateSourceFavoritesTabClicked',
  Corp_SourceAllCandidatesSelected = 'corporateSourceAllCandidatesSelected',
  Corp_SourceAllCandidatesUnselected = 'corporateSourceAllCandidatesUnselected',
  Corp_SourceAddToJobClicked = 'corporateSourceAddToJobClicked',
  Corp_SourceAddToCampaignClicked = 'corporateSourceAddToCampaignClicked',
  Corp_SourceAddToTalentPoolClicked = 'corporateSourceAddToTalentPoolClicked',
  Corp_SourceAddToVideoAssessmentClicked = 'corporateSourceAddToVideoAssessmentClicked',
  Corp_SourceNotesIconClicked = 'corporateSourceNotesIconClicked',
  Corp_SourceThumbUpDownClicked = 'corporateSourceThumbUpDownClicked',
  Corp_SourceUnlockClicked = 'corporateSourceUnlockClicked',
  Corp_CandidateDrawerAddToJobClicked = 'corporateCandidateDrawerAddToJobClicked',
  Corp_CandidateDrawerAddToCampaignClicked = 'corporateCandidateDrawerAddToCampaignClicked',
  Corp_CandidateDrawerAddToTalentPoolClicked = 'corporateCandidateDrawerAddToTalentPoolClicked',
  Corp_CandidateDrawerAddToVideoAssessmentClicked = 'corporateCandidateDrawerAddToVideoAssessmentClicked',

  // Talent Pool
  Corp_TalentPoolCreateCtaClicked = 'corporateTalentPoolCreateCtaClicked',
  Corp_TalentPoolSaveCtaClicked = 'corporateTalentPoolSaveCtaClicked',
  Corp_TalentPoolCardClicked = 'corporateTalentPoolCardClicked',
  Corp_TalentPoolCardDeleteClicked = 'corporateTalentPoolCardDeleteClicked',
  Corp_TalentPoolCardRenameClicked = 'corporateTalentPoolCardRenameClicked',
  Corp_TalentPoolAddCandidateClicked = 'corporateTalentPoolAddCandidateClicked',
  Corp_TalentPoolAddCandidateModalCancelClicked = 'corporateTalentPoolAddCandidateModalCancelClicked',
  Corp_TalentPoolAddCandidateModalSubmitClicked = 'corporateTalentPoolAddCandidateModalSubmitClicked',
  Corp_TalentPoolCopyToCtaClicked = 'corporateTalentPoolCopyToCtaClicked',
  Corp_TalentPoolCreateNewPoolCtaClicked = 'corporateTalentPoolCreateNewPoolCtaClicked',
  Corp_TalentPoolDownloadCvsClicked = 'corporateTalentPoolDownloadCvsClicked',
  Corp_TalentPoolTableRowClick = 'corporateTalentPoolTableRowClick',
  Corp_TalentPoolMoveToCtaClicked = 'corporateTalentPoolMoveToCtaClicked',
  Corp_TalentPoolDeleteAllCandidatesClicked = 'corporateTalentPoolDeleteAllCandidatesClicked',
  /* AI interview events */
  AI_INTERVIEW_SETUP_START = 'AIInterviewSetupStart',
  AI_INTERVIEW_STEP = 'AIInterviewStep',
  AI_INTERVIEW_READY = 'AIInterviewReady',
  AI_INTERVIEW_PUBLISHED = 'AIInterviewPublished',

  CANDIDATE_AI_INTERVIEW_INVITED = 'CandidateAIInterviewInvited',
  CANDIDATE_INTERVIEW_INVITED = 'CandidateInterviewInvited',

  CORPORATE_ADDED_NOTE = 'corporateAddedNote',
  CORPORATE_DELETED_NOTE = 'corporateDeletedNote',

  AI_SCREENING_TRIGGERED = 'AIScreeningStarted',
  AI_SCREENING_CRITERIA_ADDED = 'AIScreeningCriteriaAdded',
  AI_SCREENING_STARTED = 'AIScreeningClicked',
  AI_SCREENING_EDITED = 'AIScreeningEdited',
  AI_SCREENING_CRITERIA_DELETED = 'AIScreeningCriteriaDeleted',
  AI_SCREENING_CANDIDATE_EXPANDED = 'AIScreeningCandidateExpanded',
  AI_SCREENING_ERRORED = 'AIScreeningErrored',

  // HIRING MANAGER
  HM_INVITE_CTA = 'HiringManagerInviteClicked',

  // VOICE INTRO
  VOICE_INTRO_STARTED = 'voiceIntroStarted',
  VOICE_INTRO_RECORDED = 'voiceIntroRecorded',
  VOICE_INTRO_DELETED = 'voiceIntroDeleted',
  VOICE_INTRO_SUBMITTED = 'voiceIntroSubmitted',
  VOICE_INTRO_SKIPPED = 'voiceIntroSkipped',

  // OTHER
  SIDEBAR_TALK_TO_SALES_BUTTON = 'sidebarTalkToSalesButton',

  WORKSPACE_CREATE_CTA = 'workspaceCreateCta',
  NEW_WORKSPACE_CREATED = 'newWorkspaceCreated',
  WORKSPACE_DELETE_CTA = 'workspaceDeleteCta',
  WORKSPACE_DELETED = 'workspaceDeleted',
  WORKSPACE_RENAME_CTA = 'workspaceRenameCta',
  WORKSPACE_RENAMED = 'workspaceRenamed',
  WORKSPACE_SELECTED = 'workspaceSelected',
  WORKSPACE_SELECTED_FROM_SIDEBAR = 'workspaceSelectedFromSidebar',

  // CAMPAIGNS
  CAMPAIGN_CREATE_CTA = 'campaignCreateCta',
  CAMPAIGN_CREATED = 'campaignCreated',
  CAMPAIGN_LIST_TAB_CHANGED = 'campaignListTabChanged',
  CAMPAIGN_LIST_PAGE_CHANGED = 'campaignListPageChanged',
  CAMPAIGN_EDIT_CTA = 'campaignEditCta',
  CAMPAIGN_UPDATED = 'campaignUpdated',
  CAMPAIGN_MESSAGE_GENERATED = 'campaignMessageGenerated'
}
